// react
import { useState, useEffect } from 'react';
// components
import Markdown from 'markdown-to-jsx';
// styles
import styles from '../styles/sections/Greeting.module.css';
import Type from '../styles/Type.module.css';
// assets
import mdFile from '../md/greeting.md';

export const Greeting = () => {
  const [ md, setMd ] = useState("");

  useEffect( () => {
      fetch(mdFile)
          .then(result => result.text())
          .then(text => setMd(text));
  }, []);

  return (
    <Markdown
      className = {styles.greetingBox}
      children = {md}
      options={{
        overrides: {
          div: {
            props: {
              className: styles.servicesScroller,
            },
          },
        },
      }}
    />
  );
};
