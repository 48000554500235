// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DesktopHeader_header__yqMbt
{
  background-color: black;
  box-shadow: 5px 5px 15px var(--color-box-shadow);

  border-radius: 0 0 10pt 10pt;

  position: sticky;
  position: -webkit-sticky;
  top: 0;

  z-index: 10;

  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.DesktopHeader_headerLogo__QCml2
{
  display: inline-block;
  max-width: 200px;
  padding-right: 3rem;
}

/* main nav */
.DesktopHeader_navMain__VIKW3
{
    display: inline-block;
    list-style-type: none; 
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.DesktopHeader_navMainItem__rGr\\+k
{
  display: inline-block;
  margin: 2pt 2pt 2pt 30pt;

  @media (max-width: 950px)
  {
    margin-top: 0.9rem;
  }
  
  a
  {
    font-size: medium;
    color: white;
    text-decoration: none;
      
    &:hover
    {
      color: var(--color-primary);
    }
  }

  &:last-of-type
  {

    @media (max-width: 950px)
    {
      display: none;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/DesktopHeader.module.css"],"names":[],"mappings":"AAAA;;EAEE,uBAAuB;EACvB,gDAAgD;;EAEhD,4BAA4B;;EAE5B,gBAAgB;EAChB,wBAAwB;EACxB,MAAM;;EAEN,WAAW;;EAEX,aAAa;EACb,8BAA8B;EAC9B,aAAa;AACf;;AAEA;;EAEE,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA,aAAa;AACb;;IAEI,qBAAqB;IACrB,qBAAqB;IACrB,SAAS;IACT,UAAU;IACV,gBAAgB;AACpB;;AAEA;;EAEE,qBAAqB;EACrB,wBAAwB;;EAExB;;IAEE,kBAAkB;EACpB;;EAEA;;IAEE,iBAAiB;IACjB,YAAY;IACZ,qBAAqB;;IAErB;;MAEE,2BAA2B;IAC7B;EACF;;EAEA;;;IAGE;;MAEE,aAAa;IACf;EACF;AACF","sourcesContent":[".header\n{\n  background-color: black;\n  box-shadow: 5px 5px 15px var(--color-box-shadow);\n\n  border-radius: 0 0 10pt 10pt;\n\n  position: sticky;\n  position: -webkit-sticky;\n  top: 0;\n\n  z-index: 10;\n\n  display: flex;\n  justify-content: space-between;\n  padding: 1rem;\n}\n\n.headerLogo\n{\n  display: inline-block;\n  max-width: 200px;\n  padding-right: 3rem;\n}\n\n/* main nav */\n.navMain\n{\n    display: inline-block;\n    list-style-type: none; \n    margin: 0;\n    padding: 0;\n    overflow: hidden;\n}\n\n.navMainItem\n{\n  display: inline-block;\n  margin: 2pt 2pt 2pt 30pt;\n\n  @media (max-width: 950px)\n  {\n    margin-top: 0.9rem;\n  }\n  \n  a\n  {\n    font-size: medium;\n    color: white;\n    text-decoration: none;\n      \n    &:hover\n    {\n      color: var(--color-primary);\n    }\n  }\n\n  &:last-of-type\n  {\n\n    @media (max-width: 950px)\n    {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `DesktopHeader_header__yqMbt`,
	"headerLogo": `DesktopHeader_headerLogo__QCml2`,
	"navMain": `DesktopHeader_navMain__VIKW3`,
	"navMainItem": `DesktopHeader_navMainItem__rGr+k`
};
export default ___CSS_LOADER_EXPORT___;
