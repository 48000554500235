// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileHeader_header__V3Mww {
  position: fixed;
  bottom: 0.5rem;

  width: calc(100dvw - 1rem);
  
  text-align: center;
  background-color: var(--color-background);

  border-top: 2pt solid black;
}

.MobileHeader_formHotline__3T8Pn {
  position: absolute;
  margin-right: 0.5rem;
  top: 0;
  right: 0;
  transform: translateY(calc(-100% - (2pt + 0.5rem)));
  
  button {
    background-color: var(--color-emergency);
    box-shadow: 0 0 0 5px white;
  }
}

.MobileHeader_navMain__gPvUt {
  height: 2rem;
  margin: 0;
  padding-top: .5rem;
  padding-left: 0;
  padding-right: 0;
}

.MobileHeader_navMainItem__CmbnX
{
  display: inline-block;
  margin-left: 5%;
  margin-right: 5%;
}

.MobileHeader_navMainItem__CmbnX a {
  font-size: 0.95rem;
  font-style: bold;
  color: black;
  padding: 0rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/MobileHeader.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;;EAEd,0BAA0B;;EAE1B,kBAAkB;EAClB,yCAAyC;;EAEzC,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,MAAM;EACN,QAAQ;EACR,mDAAmD;;EAEnD;IACE,wCAAwC;IACxC,2BAA2B;EAC7B;AACF;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;;EAEE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,aAAa;AACf","sourcesContent":[".header {\n  position: fixed;\n  bottom: 0.5rem;\n\n  width: calc(100dvw - 1rem);\n  \n  text-align: center;\n  background-color: var(--color-background);\n\n  border-top: 2pt solid black;\n}\n\n.formHotline {\n  position: absolute;\n  margin-right: 0.5rem;\n  top: 0;\n  right: 0;\n  transform: translateY(calc(-100% - (2pt + 0.5rem)));\n  \n  button {\n    background-color: var(--color-emergency);\n    box-shadow: 0 0 0 5px white;\n  }\n}\n\n.navMain {\n  height: 2rem;\n  margin: 0;\n  padding-top: .5rem;\n  padding-left: 0;\n  padding-right: 0;\n}\n\n.navMainItem\n{\n  display: inline-block;\n  margin-left: 5%;\n  margin-right: 5%;\n}\n\n.navMainItem a {\n  font-size: 0.95rem;\n  font-style: bold;\n  color: black;\n  padding: 0rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `MobileHeader_header__V3Mww`,
	"formHotline": `MobileHeader_formHotline__3T8Pn`,
	"navMain": `MobileHeader_navMain__gPvUt`,
	"navMainItem": `MobileHeader_navMainItem__CmbnX`
};
export default ___CSS_LOADER_EXPORT___;
