// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section:has(.Guarantee_guaranteeBox__XLdSZ)
{

  img
  {
    object-fit: cover;
    object-position: 50% 0%;
    aspect-ratio: 5/3;

    @media (min-width: 1100px)
    {
      object-fit: cover;
      object-position: 50% 0%;
      aspect-ratio: 4/5;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/sections/Guarantee.module.css"],"names":[],"mappings":"AAAA;;;EAGE;;IAEE,iBAAiB;IACjB,uBAAuB;IACvB,iBAAiB;;IAEjB;;MAEE,iBAAiB;MACjB,uBAAuB;MACvB,iBAAiB;IACnB;EACF;AACF","sourcesContent":["section:has(.guaranteeBox)\n{\n\n  img\n  {\n    object-fit: cover;\n    object-position: 50% 0%;\n    aspect-ratio: 5/3;\n\n    @media (min-width: 1100px)\n    {\n      object-fit: cover;\n      object-position: 50% 0%;\n      aspect-ratio: 4/5;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"guaranteeBox": `Guarantee_guaranteeBox__XLdSZ`
};
export default ___CSS_LOADER_EXPORT___;
