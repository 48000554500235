// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DesktopHeader_header__yqMbt
{
  border-bottom: 2pt solid black;

  position: sticky;
  top: 3rem;
  
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @media (max-width: 900px)
  {
    top: 1.5rem;
  }
}

.DesktopHeader_headerLogo__QCml2
{
  height: 4rem;
  border-right: 2pt solid black;
  padding: 0.5rem;
  border-radius: 0;
  background-color: white;
}

/* main nav */
.DesktopHeader_navMain__VIKW3
{
  background-color: var(--color-background);
  list-style-type: none; 
  margin: 0;
  padding-top: 0.75rem;
  padding-left: 0;
  padding-right: 1rem;
  width: 100%;
  max-height: 5rem;
  text-align: right;
}

.DesktopHeader_navMainItem__rGr\\+k
{
  display: inline-block;
  margin: 2pt 2pt 2pt 30pt;

  &:first-child
  {
    margin-left: 1rem;
  }

  a
  {
    font-size: medium;
    color: black;
    text-decoration: none;
      
    &:hover
    {
      color: var(--color-primary);
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/DesktopHeader.module.css"],"names":[],"mappings":"AAAA;;EAEE,8BAA8B;;EAE9B,gBAAgB;EAChB,SAAS;;EAET,aAAa;EACb,oBAAoB;EACpB,8BAA8B;;EAE9B;;IAEE,WAAW;EACb;AACF;;AAEA;;EAEE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA,aAAa;AACb;;EAEE,yCAAyC;EACzC,qBAAqB;EACrB,SAAS;EACT,oBAAoB;EACpB,eAAe;EACf,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;EAEE,qBAAqB;EACrB,wBAAwB;;EAExB;;IAEE,iBAAiB;EACnB;;EAEA;;IAEE,iBAAiB;IACjB,YAAY;IACZ,qBAAqB;;IAErB;;MAEE,2BAA2B;IAC7B;EACF;AACF","sourcesContent":[".header\n{\n  border-bottom: 2pt solid black;\n\n  position: sticky;\n  top: 3rem;\n  \n  display: flex;\n  align-items: stretch;\n  justify-content: space-between;\n\n  @media (max-width: 900px)\n  {\n    top: 1.5rem;\n  }\n}\n\n.headerLogo\n{\n  height: 4rem;\n  border-right: 2pt solid black;\n  padding: 0.5rem;\n  border-radius: 0;\n  background-color: white;\n}\n\n/* main nav */\n.navMain\n{\n  background-color: var(--color-background);\n  list-style-type: none; \n  margin: 0;\n  padding-top: 0.75rem;\n  padding-left: 0;\n  padding-right: 1rem;\n  width: 100%;\n  max-height: 5rem;\n  text-align: right;\n}\n\n.navMainItem\n{\n  display: inline-block;\n  margin: 2pt 2pt 2pt 30pt;\n\n  &:first-child\n  {\n    margin-left: 1rem;\n  }\n\n  a\n  {\n    font-size: medium;\n    color: black;\n    text-decoration: none;\n      \n    &:hover\n    {\n      color: var(--color-primary);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `DesktopHeader_header__yqMbt`,
	"headerLogo": `DesktopHeader_headerLogo__QCml2`,
	"navMain": `DesktopHeader_navMain__VIKW3`,
	"navMainItem": `DesktopHeader_navMainItem__rGr+k`
};
export default ___CSS_LOADER_EXPORT___;
