import React, { useState } from 'react';
import styles from '../styles/DesktopHeader.module.css';

export const DesktopHeader = () => {
    const [ navOption, setNavOption ] = useState();

    const handleNavOption = ({ target }) => {
        
    };

    return (
    <header className={styles.header}>
        <img className={styles.headerLogo} src="images/Same-Day-CNC-logo.svg" draggable="false"/>

        <ul className={styles.navMain}>
            <li className={styles.navMainItem}><a href='#home'>Home</a></li>
            <li className={styles.navMainItem}><a href='#guarantee'>Our guarantee</a></li>
            <li className={styles.navMainItem}><a href='#contact'>Contact</a></li>
            <li className={styles.navMainItem}><a href='tel:0123456789'><button className='btnHotline'>Emergency hotline</button></a></li>
        </ul>
    </header>
    );
};
