// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__u5gg\\+
{
  color: white;

  padding-top: 2rem;
  text-align: right;
}

.Footer_copyright__ji7jm
{
    margin: 0;

    span
    {
      padding: 0.5rem;
      background-color: black;
      border-radius: 10pt;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.module.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;;EAEZ,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;;IAEI,SAAS;;IAET;;MAEE,eAAe;MACf,uBAAuB;MACvB,mBAAmB;IACrB;AACJ","sourcesContent":[".footer\n{\n  color: white;\n\n  padding-top: 2rem;\n  text-align: right;\n}\n\n.copyright\n{\n    margin: 0;\n\n    span\n    {\n      padding: 0.5rem;\n      background-color: black;\n      border-radius: 10pt;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__u5gg+`,
	"copyright": `Footer_copyright__ji7jm`
};
export default ___CSS_LOADER_EXPORT___;
