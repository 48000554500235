// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section:has(.Capabilities_capabilitiesBox__txGe6)
{

  img
  {

    @media (min-width: 1100px)
    {
      object-fit: cover;
      object-position: 50% 50%;
      aspect-ratio: 3/5;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/sections/Capabilities.module.css"],"names":[],"mappings":"AAAA;;;EAGE;;;IAGE;;MAEE,iBAAiB;MACjB,wBAAwB;MACxB,iBAAiB;IACnB;EACF;AACF","sourcesContent":["section:has(.capabilitiesBox)\n{\n\n  img\n  {\n\n    @media (min-width: 1100px)\n    {\n      object-fit: cover;\n      object-position: 50% 50%;\n      aspect-ratio: 3/5;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"capabilitiesBox": `Capabilities_capabilitiesBox__txGe6`
};
export default ___CSS_LOADER_EXPORT___;
