// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section:has(.Contact_contactBox__TpR3U)
{

  img
  {
    mix-blend-mode: multiply;

    @media (min-width: 1100px)
    {
      object-fit: cover;
      object-position: 0% 50%;
      aspect-ratio: 9/10;
    }
  }

  ul
  {
    list-style-type: none;
    padding-left: 0;
  }

  li
  {
    margin-top: 1.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/sections/Contact.module.css"],"names":[],"mappings":"AAAA;;;EAGE;;IAEE,wBAAwB;;IAExB;;MAEE,iBAAiB;MACjB,uBAAuB;MACvB,kBAAkB;IACpB;EACF;;EAEA;;IAEE,qBAAqB;IACrB,eAAe;EACjB;;EAEA;;IAEE,kBAAkB;EACpB;AACF","sourcesContent":["section:has(.contactBox)\n{\n\n  img\n  {\n    mix-blend-mode: multiply;\n\n    @media (min-width: 1100px)\n    {\n      object-fit: cover;\n      object-position: 0% 50%;\n      aspect-ratio: 9/10;\n    }\n  }\n\n  ul\n  {\n    list-style-type: none;\n    padding-left: 0;\n  }\n\n  li\n  {\n    margin-top: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactBox": `Contact_contactBox__TpR3U`
};
export default ___CSS_LOADER_EXPORT___;
