// styles
import styles from '../styles/Footer.module.css';

export const Footer = () => {
    return (
        <footer className={styles.footer}>
            <p className={styles.copyright}><span><small>© 2024 Center Tool Co</small></span></p>
        </footer>
    );
};
