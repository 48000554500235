// components
import { DesktopHeader } from './components/DesktopHeader';
import { MobileHeader } from './components/MobileHeader';
import { Footer } from './components/Footer';
import { MainContent } from './components/MainContent';
// styles
import './styles/App.css';

function App () {
  return (
    <>
      {window.innerWidth > 480 && (<DesktopHeader />)}
      <MainContent />
      {window.innerWidth <= 480 && (<MobileHeader />)}
      <div className="appBorder"></div>
    </>
  );
}

export default App;
