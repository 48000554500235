// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section:has(.Greeting_greetingBox__l-RHL)
{
  margin-top: 0;

  background-image: var(--gradient-background);

  h1
  {
    padding-top: 0;
  }

  img
  {

    @media (min-width: 1100px)
    {
      object-fit: cover;
      object-position: 50% 50%;
      aspect-ratio: 5/4;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/sections/Greeting.module.css"],"names":[],"mappings":"AAEA;;EAEE,aAAa;;EAEb,4CAA4C;;EAE5C;;IAEE,cAAc;EAChB;;EAEA;;;IAGE;;MAEE,iBAAiB;MACjB,wBAAwB;MACxB,iBAAiB;IACnB;EACF;AACF","sourcesContent":["@import \"../App.css\";\n\nsection:has(.greetingBox)\n{\n  margin-top: 0;\n\n  background-image: var(--gradient-background);\n\n  h1\n  {\n    padding-top: 0;\n  }\n\n  img\n  {\n\n    @media (min-width: 1100px)\n    {\n      object-fit: cover;\n      object-position: 50% 50%;\n      aspect-ratio: 5/4;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greetingBox": `Greeting_greetingBox__l-RHL`
};
export default ___CSS_LOADER_EXPORT___;
