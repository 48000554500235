import React, { useState } from 'react';
import styles from '../styles/MobileHeader.module.css';

export const MobileHeader = () => {
    const [ navOption, setNavOption ] = useState();

    const handleNavOption = ({ target }) => {
        
    };

    return (
    <header className={styles.header}>
        <form className={styles.formHotline}>
            <button className="btnHotline" formAction="tel:18338300247">Call hotline</button>
        </form>

        <ul className={styles.navMain}>
            <li className={styles.navMainItem}><a href='#home'>Home</a></li>
            <li className={styles.navMainItem}><a href='#guarantee'>Our guarantee</a></li>
            <li className={styles.navMainItem}><a href='#contact'>Contact</a></li>
        </ul>
    </header>
    );
};
