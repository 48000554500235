// components
import { DesktopHeader } from './components/DesktopHeader';
import { MobileHeader } from './components/MobileHeader';
import { Footer } from './components/Footer';
import { MainContent } from './components/MainContent';
// styles
import './styles/App.css';

function App () {
  return (
    <>
      {window.innerWidth > 950 && (<DesktopHeader />)}
      <MainContent />
      {window.innerWidth <= 950 && (<MobileHeader />)}
    </>
  );
}

export default App;
