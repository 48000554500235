// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainContent_mainContent__j2xNI {
  height: calc(100vh - 7rem);
  overflow: scroll;
  border-radius: 0 0 10pt 10pt;

  @media (min-width: 950px) { /* consider refactoring for desktop as default */
    height: calc(100vh - 9rem);
    margin-top: 2rem;
    border-radius: 10pt;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/MainContent.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,4BAA4B;;EAE5B,4BAA4B,gDAAgD;IAC1E,0BAA0B;IAC1B,gBAAgB;IAChB,mBAAmB;EACrB;AACF","sourcesContent":[".mainContent {\n  height: calc(100vh - 7rem);\n  overflow: scroll;\n  border-radius: 0 0 10pt 10pt;\n\n  @media (min-width: 950px) { /* consider refactoring for desktop as default */\n    height: calc(100vh - 9rem);\n    margin-top: 2rem;\n    border-radius: 10pt;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContent": `MainContent_mainContent__j2xNI`
};
export default ___CSS_LOADER_EXPORT___;
