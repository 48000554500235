// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Section_section__SAsU3
{
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;

  outline: 1pt solid black;

  scroll-margin: 8rem;

  @media (max-width: 900px)
  {
    scroll-margin: 5.5rem;
  }

  & > div
  {
    display: flow-root;
  }

  h1
  {
    margin-top: 0;

    @media (max-width: 1100px)
    {
      margin-top: 1rem;
    }
  }

  p
  {
    overflow: hidden;
  }

  p:has(img)
  {
    margin: 0;

    @media (min-width: 1100px)
    {
      float: left;
      width: 45%;
      margin-right: 3rem;
    }

    img
    {
      width: 100%;
      box-shadow: 2px 2px 5px var(--color-box-shadow);
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Section.module.css"],"names":[],"mappings":"AAAA;;EAEE,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;;EAEnB,wBAAwB;;EAExB,mBAAmB;;EAEnB;;IAEE,qBAAqB;EACvB;;EAEA;;IAEE,kBAAkB;EACpB;;EAEA;;IAEE,aAAa;;IAEb;;MAEE,gBAAgB;IAClB;EACF;;EAEA;;IAEE,gBAAgB;EAClB;;EAEA;;IAEE,SAAS;;IAET;;MAEE,WAAW;MACX,UAAU;MACV,kBAAkB;IACpB;;IAEA;;MAEE,WAAW;MACX,+CAA+C;IACjD;EACF;AACF","sourcesContent":[".section\n{\n  padding-top: 5rem;\n  padding-bottom: 5rem;\n  padding-left: 3rem;\n  padding-right: 3rem;\n\n  outline: 1pt solid black;\n\n  scroll-margin: 8rem;\n\n  @media (max-width: 900px)\n  {\n    scroll-margin: 5.5rem;\n  }\n\n  & > div\n  {\n    display: flow-root;\n  }\n\n  h1\n  {\n    margin-top: 0;\n\n    @media (max-width: 1100px)\n    {\n      margin-top: 1rem;\n    }\n  }\n\n  p\n  {\n    overflow: hidden;\n  }\n\n  p:has(img)\n  {\n    margin: 0;\n\n    @media (min-width: 1100px)\n    {\n      float: left;\n      width: 45%;\n      margin-right: 3rem;\n    }\n\n    img\n    {\n      width: 100%;\n      box-shadow: 2px 2px 5px var(--color-box-shadow);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Section_section__SAsU3`
};
export default ___CSS_LOADER_EXPORT___;
