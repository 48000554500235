// components
import { Section } from './Section';
import { Greeting } from './Greeting';
import { Capabilities } from './Capabilities';
import { Guarantee } from './Guarantee';
import { Contact } from './Contact';
// styles
import styles from '../styles/MainContent.module.css';

export const MainContent = () => {
    return (
      <div className={styles.mainContent}>
        <Section id="home">
          <Greeting />
        </Section>
        <Section id="guarantee">
          <Guarantee />
        </Section>
        <Section id="capabilities">
          <Capabilities />
        </Section>
        <Section id="contact">
          <Contact />
        </Section>
      </div>
    );
};