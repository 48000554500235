// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileHeader_header__V3Mww {
  position: relative;
  height: 5rem;
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0;

  box-shadow: 5px 5px 15px var(--color-box-shadow);
  border: 1pt solid var(--color-background);
  
  text-align: center;
  background-color: black;

  border-radius: 10pt;
}

.MobileHeader_formHotline__3T8Pn {
  position: absolute;
  margin-right: 1em;
  top: 0;
  right: 0;
  transform: translateY(calc(-100% - 2em));
  
  button {
    background-color: var(--color-emergency);
    box-shadow: 2px 2px 5px var(--color-box-shadow);

  }
}

.MobileHeader_navMain__gPvUt {
  margin: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  transform: translateY(1.5rem);
}

.MobileHeader_navMainItem__CmbnX
{
  display: inline-block;
  margin-left: 5%;
  margin-right: 5%;
}

.MobileHeader_navMainItem__CmbnX a {
  font-size: 0.95rem;
  font-style: bold;
  color: white;
  padding: 0rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/MobileHeader.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;;EAEV,gDAAgD;EAChD,yCAAyC;;EAEzC,kBAAkB;EAClB,uBAAuB;;EAEvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,MAAM;EACN,QAAQ;EACR,wCAAwC;;EAExC;IACE,wCAAwC;IACxC,+CAA+C;;EAEjD;AACF;;AAEA;EACE,SAAS;EACT,oBAAoB;EACpB,qBAAqB;EACrB,6BAA6B;AAC/B;;AAEA;;EAEE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,aAAa;AACf","sourcesContent":[".header {\n  position: relative;\n  height: 5rem;\n  margin-top: 1rem;\n  margin-bottom: 0;\n  padding: 0;\n\n  box-shadow: 5px 5px 15px var(--color-box-shadow);\n  border: 1pt solid var(--color-background);\n  \n  text-align: center;\n  background-color: black;\n\n  border-radius: 10pt;\n}\n\n.formHotline {\n  position: absolute;\n  margin-right: 1em;\n  top: 0;\n  right: 0;\n  transform: translateY(calc(-100% - 2em));\n  \n  button {\n    background-color: var(--color-emergency);\n    box-shadow: 2px 2px 5px var(--color-box-shadow);\n\n  }\n}\n\n.navMain {\n  margin: 0;\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n  transform: translateY(1.5rem);\n}\n\n.navMainItem\n{\n  display: inline-block;\n  margin-left: 5%;\n  margin-right: 5%;\n}\n\n.navMainItem a {\n  font-size: 0.95rem;\n  font-style: bold;\n  color: white;\n  padding: 0rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `MobileHeader_header__V3Mww`,
	"formHotline": `MobileHeader_formHotline__3T8Pn`,
	"navMain": `MobileHeader_navMain__gPvUt`,
	"navMainItem": `MobileHeader_navMainItem__CmbnX`
};
export default ___CSS_LOADER_EXPORT___;
