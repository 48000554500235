// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section:has(.Greeting_greetingBox__l-RHL)
{
  margin-top: 0;

  p:has(img)
  {
    float: none;

    width: 100%;
    text-align: center;

    margin: 0;

    img
    {
      max-width: 50rem;
      box-shadow: none;
    }
  }

  .Greeting_servicesScroller__Nrn-K
  {
    white-space: nowrap;
    overflow: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 3rem;
    padding-right: 0;
    margin-left: -3rem;
    margin-right: -3rem;
  }

  figure
  {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 2rem;

    text-align: center;
    font-size: 1.5rem;
  }

  figure img
  {
    width: 15rem;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  h1
  {
    padding-top: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/sections/Greeting.module.css"],"names":[],"mappings":"AAEA;;EAEE,aAAa;;EAEb;;IAEE,WAAW;;IAEX,WAAW;IACX,kBAAkB;;IAElB,SAAS;;IAET;;MAEE,gBAAgB;MAChB,gBAAgB;IAClB;EACF;;EAEA;;IAEE,mBAAmB;IACnB,cAAc;IACd,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;;IAEE,qBAAqB;IACrB,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,kBAAkB;;IAElB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;;IAEE,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;;IAEE,cAAc;EAChB;AACF","sourcesContent":["@import \"../App.css\";\n\nsection:has(.greetingBox)\n{\n  margin-top: 0;\n\n  p:has(img)\n  {\n    float: none;\n\n    width: 100%;\n    text-align: center;\n\n    margin: 0;\n\n    img\n    {\n      max-width: 50rem;\n      box-shadow: none;\n    }\n  }\n\n  .servicesScroller\n  {\n    white-space: nowrap;\n    overflow: auto;\n    padding-top: 1rem;\n    padding-bottom: 1rem;\n    padding-left: 3rem;\n    padding-right: 0;\n    margin-left: -3rem;\n    margin-right: -3rem;\n  }\n\n  figure\n  {\n    display: inline-block;\n    margin-top: 0;\n    margin-bottom: 0;\n    margin-left: 0;\n    margin-right: 2rem;\n\n    text-align: center;\n    font-size: 1.5rem;\n  }\n\n  figure img\n  {\n    width: 15rem;\n    aspect-ratio: 1/1;\n    object-fit: cover;\n  }\n\n  h1\n  {\n    padding-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greetingBox": `Greeting_greetingBox__l-RHL`,
	"servicesScroller": `Greeting_servicesScroller__Nrn-K`
};
export default ___CSS_LOADER_EXPORT___;
