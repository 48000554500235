// react
import { useState, useEffect } from 'react';
// components
import Markdown from 'markdown-to-jsx';
// styles
import styles from '../styles/sections/Capabilities.module.css';
import Type from '../styles/Type.module.css';
// assets
import mdFile from '../md/capabilities.md';

export const Capabilities = () => {
    const [ md, setMd ] = useState("");

    useEffect( () => {
        fetch(mdFile)
        .then(result => result.text())
        .then(text => setMd(text));
    }, []);

    return (
        <Markdown
            className = {styles.capabilitiesBox}
            children = {md}
        />
    );
};