// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*  paragraph styles
    ------------------------------- */

h1
{
    font-size: 40pt;
    font-weight: 900;
    margin-bottom: 0;
}

h2
{
    font-size: xx-large;
}

p
{
    font-size: large;
}

a
{
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Type.module.css"],"names":[],"mappings":"AAAA;qCACqC;;AAErC;;IAEI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;;IAEI,mBAAmB;AACvB;;AAEA;;IAEI,gBAAgB;AACpB;;AAEA;;IAEI,qBAAqB;AACzB","sourcesContent":["/*  paragraph styles\n    ------------------------------- */\n\nh1\n{\n    font-size: 40pt;\n    font-weight: 900;\n    margin-bottom: 0;\n}\n\nh2\n{\n    font-size: xx-large;\n}\n\np\n{\n    font-size: large;\n}\n\na\n{\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
