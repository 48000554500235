// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/noise.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Section_section__SAsU3
{
  background-color: ghostwhite;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); 
  background-blend-mode: multiply;

  box-shadow: 5px 5px 10px var(--color-box-shadow);

  border-radius: 10pt;

  margin-top: 2rem;

  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;

  scroll-margin-top: 7rem;
  overflow: hidden;

  @media (max-width: 600px)
  {
    margin-top: 1rem;
    margin-left: 0;
    margin-right: 0;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    border-radius: 20px;
  }

  h1
  {
    margin-top: 0;

    @media (max-width: 1100px)
    {
      margin-top: 1rem;
    }
  }

  p
  {
    overflow: hidden;
  }

  p:has(img)
  {
    margin: 0;
    overflow: visible;

    @media (min-width: 1100px)
    {
      float: left;
      width: 45%;
      margin-right: 3rem;
    }

    img
    {
      width: 100%;
      box-shadow: 2px 2px 5px var(--color-box-shadow);
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Section.module.css"],"names":[],"mappings":"AAAA;;EAEE,4BAA4B;EAC5B,yDAAiD;EACjD,+BAA+B;;EAE/B,gDAAgD;;EAEhD,mBAAmB;;EAEnB,gBAAgB;;EAEhB,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;;EAEnB,uBAAuB;EACvB,gBAAgB;;EAEhB;;IAEE,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;;IAEnB,mBAAmB;EACrB;;EAEA;;IAEE,aAAa;;IAEb;;MAEE,gBAAgB;IAClB;EACF;;EAEA;;IAEE,gBAAgB;EAClB;;EAEA;;IAEE,SAAS;IACT,iBAAiB;;IAEjB;;MAEE,WAAW;MACX,UAAU;MACV,kBAAkB;IACpB;;IAEA;;MAEE,WAAW;MACX,+CAA+C;IACjD;EACF;AACF","sourcesContent":[".section\n{\n  background-color: ghostwhite;\n  background-image: url('/public/images/noise.svg'); \n  background-blend-mode: multiply;\n\n  box-shadow: 5px 5px 10px var(--color-box-shadow);\n\n  border-radius: 10pt;\n\n  margin-top: 2rem;\n\n  padding-top: 5rem;\n  padding-bottom: 5rem;\n  padding-left: 3rem;\n  padding-right: 3rem;\n\n  scroll-margin-top: 7rem;\n  overflow: hidden;\n\n  @media (max-width: 600px)\n  {\n    margin-top: 1rem;\n    margin-left: 0;\n    margin-right: 0;\n    padding-top: 1rem;\n    padding-left: 1rem;\n    padding-right: 1rem;\n\n    border-radius: 20px;\n  }\n\n  h1\n  {\n    margin-top: 0;\n\n    @media (max-width: 1100px)\n    {\n      margin-top: 1rem;\n    }\n  }\n\n  p\n  {\n    overflow: hidden;\n  }\n\n  p:has(img)\n  {\n    margin: 0;\n    overflow: visible;\n\n    @media (min-width: 1100px)\n    {\n      float: left;\n      width: 45%;\n      margin-right: 3rem;\n    }\n\n    img\n    {\n      width: 100%;\n      box-shadow: 2px 2px 5px var(--color-box-shadow);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Section_section__SAsU3`
};
export default ___CSS_LOADER_EXPORT___;
